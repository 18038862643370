/**
 * Import conditioner
 * As per: https://pqina.nl/conditioner/
 */
import * as conditioner from 'conditioner-core';

/**
 * Configure conditioner to work with dynamic imports & webpack
 */
conditioner.addPlugin({
    // converts module aliases to paths
    moduleSetName: (name) => `${name}.js`,

    // get the module constructor
    moduleGetConstructor: (module) => module.default,

    // define default constructor args
    moduleSetConstructorArguments: (name, element) => [
        element,
        { ...element.dataset }
    ],

    // override the import
    moduleImport: (
        name // eslint-disable-line
    ) => import(/* https://webpack.js.org/api/module-methods/#import- */
        /* set to "eager" to create a single chunk for all modules */
        /* set to "lazy" to create a separate chunk for each module */
        /* webpackChunkName: "[request]" */
        /* webpackMode: "lazy" */
        '../modules/' + name // eslint-disable-line
    )
});

conditioner.addPlugin({
    // the plugin "monitor" hook
    monitor: {
        // the name of our monitor, not prefixed with "@"
        name: 'visible',

        // the monitor factory method, this will create our monitor
        create: (context, element) => ({
            // current match state
            matches: false,

            // called by conditioner to start listening for changes
            addListener(change) {
                new IntersectionObserver((entries) => {
                    this.matches = entries.pop().isIntersecting === context;

                    // inform conditioner of the new state
                    change();
                }).observe(element);
            }
        })
    }
});

conditioner.hydrate(document.documentElement);
